@use "../../variables" as v;

footer p {
  color: v.$color31;
}
footer .footer-top {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: v.$color34;
}
footer .footer-item .title {
  margin-bottom: 30px;
  font-size: 30px;
  color: v.$color31;
}
footer .footer-item .footer-about ul {
  margin: 0px;
}
footer .footer-item .footer-about ul li {
  list-style: none;
  margin-bottom: 6px;
  color: v.$color31;
}
footer .footer-item .footer-about ul li span {
  height: 36px;
  width: 36px;
  text-align: center;
  border-radius: 2px;
  display: inline-block;
  line-height: 36px;
  background-color: v.$color25;
  color: v.$color31;
  margin-right: 8px;
}
footer .footer-item .footer-post li {
  list-style: none;
  border-bottom: 1px solid v.$color25;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
footer .footer-item .footer-post li:last-child {
  border: none;
  margin: 0px;
  padding: 0px;
}
footer .footer-item .footer-post .image {
  float: left;
  margin-right: 8px;
  margin-top: 5px;
}
footer .footer-item .footer-post .content {
  display: table;
}
footer .footer-item .footer-post .content p {
  margin: 0px;
}
footer .footer-item .footer-post .content p a {
  text-decoration: none;
  color: v.$color31;
}
footer .footer-item .footer-post .content span {
  font-size: 15px;
  font-style: italic;
  color: v.$color31;
  line-height: 18px;
}
footer .footer-item .twitter-post li {
  list-style: none;
  border-bottom: 1px solid v.$color25;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
footer .footer-item .twitter-post li:last-child {
  border: none;
  margin: 0px;
  padding: 0px;
}
footer .footer-item .twitter-post .icon {
  display: inline-block;
  float: left;
  color: v.$color31;
  line-height: 22px;
  font-size: 30px;
  margin-right: 10px;
  margin-top: 5px;
}
footer .footer-item .twitter-post .content {
  display: table;
}
footer .footer-item .twitter-post .content p {
  margin: 0px;
}
footer .footer-item .twitter-post .content span {
  font-size: 15px;
  font-style: italic;
  color: v.$color31;
}
footer .footer-item .photos {
  margin: -5px;
  overflow: hidden;
}
footer .footer-item .photos li {
  list-style: none;
  float: left;
  padding: 5px;
  width: 50%;
}
footer .footer-item .photos li img {
  width: 100%;
}
footer .footer-bottom {
  border-top: 1px solid v.$color31;
  background-color: v.$color28;
  padding: 30px 0px;
}
footer .footer-bottom p {
  font-weight: 700;
  margin: 0px;
  line-height: 40px;
  color: v.$color31;
}
footer .footer-bottom p a {
  color: v.$color31;
  text-decoration: none;
}
footer .footer-bottom .social-default {
  float: right;
}
