@use "../../variables" as v;

header {
  position: sticky;
  top: 0;
  z-index: 100;
}
.addresses li{
  color: v.$color5 !important;
}
.header-top {
  background-color: v.$color2;
  color: v.$color5;
  padding: 0.55rem;
  overflow: hidden;
  font-size: 1rem 0.5rem;
  font-family: fa-style-family;
}
.header_link {
  color: v.$color5;
  text-decoration: none;  
}
.main-menu {
  position: relative;
  background-color: v.$color7;
  z-index: 9;
  padding: 0.5rem;
  font-family: fa-style-family;
}
.header-auth {
  background-color: v.$color2;
  font-size: 1rem 0.7rem;
  font-family: fa-style-family;
  color: v.$color8;
}
